

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL5PostLabPart6',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part5_6: null,
      },
      questions: [
        {
          text: 'What did you do (if anything) to mitigate the effects of error? What more could you have done to mitigate the effects of error?',
          inputName: 'part5_6',
        },
      ],
    };
  },
};
